import React from 'react'
import ImmoCard from '../components/immo_card'
import Layout from '../components/indexLayout'
import { graphql } from 'gatsby'

function getBlogs(data) {
  console.log(data)
  let blogs = []
  let blogList = data.allMarkdownRemark.edges

  blogList.forEach(element => {
    blogs.push(<ImmoCard data={element.node.frontmatter} />)
  })

  return blogs
}

const ImmobilienPage = ({ data }) => (
  <Layout>
    <div className="page">
      <div className="container">
        <section className="blog-section">
          <div className="blog-posts">{getBlogs(data)}</div>
          {/*
          
            <div className="blog-newsletter">
              <div className="news-card">
                <img alt="" src="" />
              </div>
            </div>
            */}
        </section>
      </div>
    </div>
  </Layout>
)

export default ImmobilienPage

export const blogsQuery = graphql`
  query blogsQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blogs/.*md$/" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcSet
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            tags
            title
            description
            date(formatString: "DD-MMM-YYYY")
            price
            living_space
            plot_size
            number_rooms
          }
        }
      }
    }
  }
  `
/*  imageOne: file(relativePath: { eq: "images/noun_price_2405300.png" }) {
    ...firstSiteImages
  }
  imageTwo: file(relativePath: { eq: "images/noun_price_2405300.png" }) {
    ...firstSiteImages
  }
  imageThree: file(relativePath: { eq: "images/noun_price_2405300.png" }) {
    ...firstSiteImages
  }*/