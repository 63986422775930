import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

const ImmoCard = ({ data }) => (

  <Link className="blog-card elevate white-bg" to={data.slug}>
    <div className="cover">
      <Img style={{ height: '100%' }} fluid={data.cover.childImageSharp.fluid} />
    </div>
    <div className="card-content">
      <div className="tags">{data.tags}</div>
      <Link className="title" to={data.slug}><h2>{data.title}</h2></Link>
      <div className="description">{data.description}</div>
      <div className="author-box">


        <div className="immo-details">
            <p className="author-name"><b>{data.price}</b></p>
            <p className="author-name">Kaufpreis</p>
        </div>

        {
          //<div className="dash_vertical" />
        }

        <div className="immo-details">
            <p className="author-name"><b>{data.number_rooms}</b></p>
            <p className="author-name">Zimmer</p>
        </div>

        {
          //<div className="dash_vertical" />
        }

        <div className="immo-details">
            <p className="author-name"><b>{data.living_space}</b></p>
            <p className="author-name">Wohnfläche</p>
        </div>

        {
          /*
          Grundstücksfläche ist optional, da nur für Häuser sinnvoll.
          */
        }
        {
          /*data.plot_size ? (
            <div className="dash_vertical" />
          )
          : null*/
        }

        {
          data.plot_size ? (
            <div className="immo-details">
              <p className="author-name"><b>{data.plot_size}</b></p>
              <p className="author-name">Grundstücksfläche</p>
            </div>
          )
          : null
        }

      </div>
    </div>
  </Link>
)

export default ImmoCard
